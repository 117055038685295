import React, { useState } from "react";
// import Slider from "react-slick";
// import FsLightbox from "fslightbox-react";
// import * as Icon from "react-feather";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"


import Sectiontitle from "../components/Sectiontitle";
// import Service from '../components/Service';
// import Testimonial from '../components/Testimonial';
import SEO from "../components/seo"

const Service = React.lazy(() =>
  import("../components/Service")
)

function calcAge(dateString) {
  var birthday = +new Date(dateString);
  return~~ ((Date.now() - birthday) / (31557600000));
}

function About(){
  const isSSR = typeof window === "undefined"
  
  // const [toggler, setToggler] = useState(false);
  const [information] = useState({
    name: 'Steven Lambe',
    age: calcAge('September 23, 1991'),
    nationality: 'Canadian',
    language: ['English'],
    address: 'Sarnia, Ontario, Canada'
  });
  const [services] = useState([
    {
      title: 'Photography',
      icon: 'camera',
      details: 'Hobbyist photographer with likely too much camera to know what to do with.'
    },
    {
      title: 'Video Games',
      icon: 'game',
      details: 'From playing to making I still love video games in my spare time.'
    },
    {
      title: 'Open Source',
      icon: 'github',
      details: 'I contribute where I can through code, documentation, and general help contributions.'
    }
  ]);
  // const [reviews, setReviews] = useState([]);

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "fighter.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            layout: CONSTRAINED
          )
        }
      }
    }
  `)
  // const [services] = useState([]);
  // const [reviews] = useState([]);

  // const sliderSettings = {
  //   dots: false,
  //   infinite: true,
  //   arrows: false,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 2,
  //   autoplay: true,
  //   autoplaySpeed: 6000,
  //   pauseOnHover: true,
  //   adaptiveHeight: true,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     },
  //   ]
  // };

  // const handleToggler = (event) => {
  //   setToggler({
  //     toggler: event
  //   })
  // }

  return (
    <>
      <SEO title="About" />
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="About Me" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-about-image">
                <Img
                  image={data.file.childImageSharp.gatsbyImageData}
                  alt="about"
                  width="750"
                />
                {/* <span className="mi-about-image-icon">
                  <Icon.ZoomIn />
                </span> */}
                {/* <FsLightbox
                  toggler={toggler}
                  sources={[data.file.childImageSharp.fixed]}
                /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mi-about-content">
                <h3>
                  I am <span className="color-theme">{information.name}</span>
                </h3>
                <p>
                  I am a full stack developer that tries to keep code tested and clean with experience in relational and non-relational databases.
                </p>
                <ul>
                  {!information.name ? null : <li>
                    <b>Full Name</b> {information.name}
                  </li>}
                  {!information.age ? null : <li>
                    <b>Age</b> {information.age} Years
                  </li>}
                  {!information.phone ? null : <li>
                    <b>Phone</b> {information.phone}
                  </li>}
                  {!information.nationality ? null : <li>
                    <b>Nationality</b> {information.nationality}
                  </li>}
                  {!information.language ? null : <li>
                    <b>Languages</b> {information.language}
                  </li>}
                  {!information.email ? null : <li>
                    <b>Email</b> {information.email}
                  </li>}
                  {!information.address ? null : <li>
                    <b>Location</b> {information.address}
                  </li>}
                  {!information.freelanceStatus ? null : <li>
                    <b>Freelance</b> {information.freelanceStatus}
                  </li>}
                </ul>
                {/* <a href={information.cvfile} className="mi-button">Download CV</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Things I Do" />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
            {!isSSR && (
                <React.Suspense fallback={<div />}>
                  {services.map(service => (
                    <div className="col-lg-4 col-md-6 col-12 mt-30" key={service.title}>
                      <Service content={service}/>
                    </div>
                  ))}
                </React.Suspense>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Reviews" />
          <div className="row justify-content-center">
            <div className="col-12">
              <Slider className="mi-testimonial-slider" {...sliderSettings}>
                {reviews.map(review =>(
                  <Testimonial key={review.id} content={review}/>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default About;
